<template>
  <div class="socialMedia">
    <div class="brand" v-for="(media, key, index) in socialMedia" :key="'social-' + key + index">
      <a :href="media.link" target="_blank">
        <svg-icon :iconClass="media.img"></svg-icon>  
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocialMedia',
  data() {
    return {
      socialMedia: [
        { name: 'Facebook', link: 'https://www.facebook.com/puprime', img: 'facebook' },
        { name: 'Linked In', link: 'https://www.linkedin.com/company/puprime/', img: 'linked_in' },
        { name: 'Twitter', link: 'https://twitter.com/puprime', img: 'twitter' },
        {
          name: 'Instagram',
          link: 'https://www.instagram.com/puprime_more_than_trading/',
          img: 'instagram'
        },
        {
          name: 'Youtube',
          link: 'https://www.youtube.com/channel/UCmhvCX6JOPiHvfnveFKmWxA/videos',
          img: 'youtube'
        },
        { name: 'Tiktok', link: 'https://www.tiktok.com/@puprime', img: 'tiktok' }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.socialMedia {
  display: flex;
  .svg-icon {
    font-size: 24px;
    @include rtl-sass-prop(margin-right, margin-left, 16px);
    &:hover {
      color: $primary;
    }
  }
}
</style>
